import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/opt/build/repo/src/templates/page.jsx";
import { Button } from '../../components/Button';
import { FormJoin } from '../../components/FormJoin';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <MDXTag name="h2" components={components}>{`Ak ovládaš cudzie jazyky, pomôž s prekladom a prines tak zaujímavé myšlienky aj pre tých, ktorí sa v zahraničných zdrojoch neorientujú.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Nie vždy treba vymýšľať koleso nanovo. Mnoho kvalitného obsahu sa však v slovenčine nevytvára. Ak ovládaš cudzie jazyky, pomôž s prekladom a prines tak zaujímavé myšlienky aj pre tých, ktorí sa v zahraničných zdrojoch neorientujú.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Spoločne sme preložili projekt `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://crypto.christmas/"
        }}>{`crypto.christmas`}</MDXTag>{`, zameraný na digitálnu bezpečnosť a rozhodli sa ho premenovať na `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.paralelnapoliskosice.sk/rychlokurz-bezpecnosti"
        }}>{`Rýchlokurz digitálnej bezpečnosti`}</MDXTag>{`, čo nakoniec vyústilo aj do `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "/zapoj-sa/program"
        }}>{`verejnej udalosti s prednáškami`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Ako sa zapojiť?`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Aktuálne pracujeme na preklade príručky `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://ssd.eff.org/"
          }}>{`Surveillance Self-Defense`}</MDXTag>{`, ktorá obsahuje množstvo dôležitých informácií, opäť týkajúcich sa bezpečnosti avšak nie len digitálnej. V zdieľanom dokumente sa nachádza `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://docs.google.com/document/d/15H1HjMughUThNjEXWMAv6FtqPlHI2V5DyC1fq0qBc2E/edit?usp=sharing"
          }}>{`postup a obsah`}</MDXTag>{` a prebieha všetka komunikácia.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Ak máš návrh na iný obsah, ktorý je v súlade s našou víziou a s ktorým by si potreboval pomoc, kontaktuj nás.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`Preberáme len obsah, ktorého licencia to umožňuje alebo so súhlasom autora.`}</MDXTag>
      <FormJoin category="Pomoc s prekladom">
  Napíš nám svoju predstavu. Pripoj aj odkaz na dokument, ktorý chceš preložiť.
  Si z Košíc alebo tu vieš dochádzať?
      </FormJoin>
      <Button to="/zapoj-sa">Ďalšie možnosti ako sa zapojiť</Button>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      